import React from 'react';
import PropTypes from 'prop-types';

import styles from './popup.module.scss';

export default function PopUp({ children, handleClose }) {
  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.container}>
        { handleClose !== null && (<div onClick={handleClose} className={styles.close}>X</div>)}
        <div className={styles.inner}>
          {children}
        </div>
      </div>
    </>
  );
}

PopUp.propTypes = {
  children: PropTypes.any,
  handleClose: PropTypes.func,
};

PopUp.defaultProps = {
  children: null,
  handleClose: null,
};
