import trees from './trees';
import patches from './patches';
import houses from './houses';

const scenery = [
  ...trees,
  ...patches,
  ...houses,
];

export default {
  scenery,
};
