import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './stage.module.scss';

export default function Stage({
  x,
  y,
  width,
  height,
  tile,
  children,
  noBackground,
}) {
  const [bottomTiles, setBottomTiles] = useState([]);
  const [leftTiles, setLeftTiles] = useState([]);
  const [rightTiles, setRightTiles] = useState([]);
  const [topTiles, setTopTiles] = useState([]);

  useEffect(() => {
    const nextBottomTiles = [];
    const nextLeftTiles = [];
    const nextRightTiles = [];
    const nextTopTiles = [];
    // bottom
    for (let i = 0; i <= width - 2; i += tile) {
      nextBottomTiles.push(
        <div className={styles.bottom} style={{ height: tile, width: tile, left: i }} key={i} />
      );
    }
    setBottomTiles(nextBottomTiles);

    // left
    for (let i = 0; i <= height - 2; i += tile) {
      nextLeftTiles.push(
        <div className={styles.left} style={{ height: tile, width: tile, top: i }} key={i} />
      );
    }
    setLeftTiles(nextLeftTiles);

    // right
    for (let i = 0; i <= height - 2; i += tile) {
      nextRightTiles.push(
        <div className={styles.right} style={{ height: tile, width: tile, top: i }} key={i} />
      );
    }
    setRightTiles(nextRightTiles);

    // top
    for (let i = 0; i <= height - 2; i += tile) {
      nextTopTiles.push(
        <div className={styles.top} style={{ height: tile, width: tile, left: i }} key={i} />
      );
    }
    setTopTiles(nextTopTiles);
  });

  return (
    <div
      className={noBackground ? styles.stageNoBackground : styles.stage}
      style={{
        top: y,
        left: x,
        height,
        width,
      }}
    >
      {children}
      {
        !noBackground && (
          <>
            {bottomTiles}
            {leftTiles}
            {rightTiles}
            {topTiles}
            <div className={styles.bottomRight} style={{ height: tile, width: tile }} />
            <div className={styles.bottomLeft} style={{ height: tile, width: tile }} />
            <div className={styles.topRight} style={{ height: tile, width: tile }} />
            <div className={styles.topLeft} style={{ height: tile, width: tile }} />
          </>
        )
      }
    </div>
  );
}

Stage.propTypes = {
  noBackground: PropTypes.bool,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  tile: PropTypes.number.isRequired,
  children: PropTypes.any,
};

Stage.defaultProps = {
  noBackground: false,
  children: null,
};
