import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import './global.scss';
import styles from './global.module.scss';

import Home from './ui/home';
import Portfolio from './ui/portfolio';

function createTransition(callback) {
  return async (history, navigate, external) => {
    if (typeof navigate !== 'undefined' && navigate.indexOf('popup:') >= 0) {
      callback(null, navigate, external);
      return;
    }

    if (typeof external !== 'undefined') {
      await setTimeout(() => { callback(true, navigate, external); }, 100);
      await setTimeout(() => { window.open(external, '_blank'); }, 600);
      await setTimeout(() => { callback(false, navigate, external); }, 1100);
    } else {
      await setTimeout(() => { callback(true, navigate, external); }, 100);
      await setTimeout(() => { history.push(navigate); }, 600);
      await setTimeout(() => { callback(false, navigate, external); }, 1100);
    }
  };
}

function Router() {
  const [animate, setAnimate] = useState(false);
  const [contact, setContact] = useState(false);
  const [apps, setApps] = useState(false);
  const [comingSoon, setComingSoon] = useState(false);
  const [play, setPlay] = useState(false);
  // const audio = useRef(null);

  const handleGameStart = () => {
    setPlay(true);
    // audio.current.volume = 0.1;
    // audio.current.play();
  };

  const startTransition = createTransition((bool, navigate, external) => {
    if (bool !== null) {
      setAnimate(bool);
      if (typeof external === 'undefined') {
        setPlay(false);
        // audio.current.pause();
        // audio.current.currentTime = 0;
      }
      return;
    }

    if (navigate.indexOf('contact') >= 0) setContact(true);
    if (navigate.indexOf('apps') >= 0) setApps(true);
    if (navigate.indexOf('coming-soon') >= 0) setComingSoon(true);
  });

  return (
    <BrowserRouter>
      <>
        <div className={animate ? styles.animationExecution : styles.animationSetup} />
        <Switch>
          <Route
            exact
            path="/"
            component={props => (
              <Home
                startTransition={startTransition}
                contact={contact}
                closeContact={() => setContact(false)}
                comingSoon={comingSoon}
                closeComingSoon={() => setComingSoon(false)}
                apps={apps}
                closeApps={() => setApps(false)}
                handleClick={handleGameStart}
                play={play}
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/portfolio"
            component={props => <Portfolio startTransition={startTransition} {...props} />}
          />

        </Switch>
      </>
    </BrowserRouter>
  );
}

ReactDOM.render(<Router />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
