import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './guy.module.scss';

function useGuy(direction, x, y, sX, sY) {
  const [guyVersion, setGuyVersion] = useState(styles.sd);
  const [count, setCount] = useState(0);
  const [lu, setLastUpdate] = useState(null);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (lu === null || direction !== lu.direction || x !== lu.x || y !== lu.y || sX !== lu.sX || sY !== lu.sY) {
        if (direction === 'down') {
          if (count % 2) {
            setGuyVersion(styles.md1);
          } else {
            setGuyVersion(styles.md2);
          }
          setLastUpdate({
            direction,
            x,
            y,
            sX,
            sY,
          });
          setCount(count + 1);
        }

        if (direction === 'right') {
          if (count % 2) {
            setGuyVersion(styles.mr1);
          } else {
            setGuyVersion(styles.mr2);
          }
          setLastUpdate({
            direction,
            x,
            y,
            sX,
            sY,
          });
          setCount(count + 1);
        }

        if (direction === 'left') {
          if (count % 2) {
            setGuyVersion(styles.ml1);
          } else {
            setGuyVersion(styles.ml2);
          }
          setLastUpdate({
            direction,
            x,
            y,
            sX,
            sY,
          });
          setCount(count + 1);
        }

        if (direction === 'up') {
          if (count % 2) {
            setGuyVersion(styles.mu1);
          } else {
            setGuyVersion(styles.mu2);
          }
          setLastUpdate({
            direction,
            x,
            y,
            sX,
            sY,
          });
          setCount(count + 1);
        }

        // if guy has not moved for half a second
        setTimeout(() => {
          if (!direction || direction === 'down') {
            setGuyVersion(styles.sd);
          } else if (direction === 'up') {
            setGuyVersion(styles.su);
          } else if (direction === 'right') {
            setGuyVersion(styles.sr);
          } else if (direction === 'left') {
            setGuyVersion(styles.sl);
          }
        }, 200);
      }
    });
  });


  return guyVersion;
}

export default function Guy({
  x,
  y,
  sX,
  sY,
  direction,
  tile,
  elem,
}) {
  const styledGuy = useGuy(direction, x, y, sX, sY);
  return (
    <div
      ref={elem}
      className={styledGuy}
      style={{
        top: y,
        left: x,
        height: tile,
        width: tile,
      }}
    />
  );
}

Guy.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  sX: PropTypes.number.isRequired,
  sY: PropTypes.number.isRequired,
  direction: PropTypes.string.isRequired,
  tile: PropTypes.number.isRequired,
  elem: PropTypes.any.isRequired,
};
