import React from 'react';
import PopUp from '../../popup';

export default function Contact({ ...props }) {
  return (
    <PopUp {...props}>
      <h1>Contact</h1>
      <h2>Feel free to get in contact with me by emailing me at:</h2>
      <h3><strong>contact@mayger.dev</strong></h3>
    </PopUp>
  );
}
