import React from 'react';
import { Link } from 'react-router-dom';

import PopUp from '../popup';

export default function GameInfo({ handleClick }) {
  return (
    <PopUp>
      <h1>Will Mayger - Portfolio Site</h1>
      <h2>I am a Senior/Staff Software Engineer who specializes in <strong>JavaScript</strong>, <strong>TypeScript</strong>, <strong>ReactJS</strong>, and <strong>React Native</strong>.</h2>
      <p>
        This site has been built with <strong>JavaScript</strong>, <strong>ReactJs</strong>, <strong>ReactJs Hooks</strong>, and <strong>CSS Modules</strong>.
      </p>

      <h3>How to use</h3>
      <p data-class="no-margin">
        Use either your keyboards arrow buttons, or the on screen arrow buttons (Located at the bottom right), to move around.
      </p>
      <p data-class="no-margin">
        Go into the doors of the buildings to access different parts of the site.
      </p>

      <button onClick={handleClick} type="button">
        Start
      </button>

      <Link to="/portfolio">
        Go straight to work examples.
      </Link>
    </PopUp>
  );
}
