import { useEffect, useState } from 'react';


// USE MOVEMENT HOOK
function inMovementBounds(ref, dir, tile) {
  if (ref.current === null) return false;
  const localGuy = ref.current.getBoundingClientRect();

  if ((localGuy.x) <= (tile * 2) && dir === 'left') {
    return true;
  }

  if ((localGuy.x + tile) >= window.innerWidth - (tile * 2) && dir === 'right') {
    return true;
  }

  if ((localGuy.y) <= (tile * 2) && dir === 'up') {
    return true;
  }

  if ((localGuy.y + tile) >= (window.innerHeight - (tile * 2)) && dir === 'down') {
    return true;
  }
  return false;
}

function inStageBounds(x, y, dir, stageY, stageX, tile, STAGE) {
  if (dir === 'left' && x - stageX > tile) {
    return true;
  }

  if (dir === 'right' && (x - stageX) + tile < (STAGE.width - tile)) {
    return true;
  }

  if (dir === 'up' && y - stageY > tile) {
    return true;
  }

  if (dir === 'down' && (y + tile) - stageY < (STAGE.height - tile)) {
    return true;
  }
  return false;
}

function inPlaceBounds(x, y, tile, place, stageX, stageY, direction, triggerTransition) {
  let nextX = x;
  let nextY = y;
  if (direction === 'right') {
    nextX = x + tile;
  } else if (direction === 'left') {
    nextX = x - tile;
  } else if (direction === 'up') {
    nextY = y - tile;
  } else if (direction === 'down') {
    nextY = y + tile;
  }

  const doorConditional = place.doorX ? (nextX === (place.x + place.doorX + stageX)
    && nextY === (place.y + stageY + place.height - tile)) : NaN;

  const rightConditional = (nextX < ((place.x + stageX) + place.width));
  const leftConditional = ((nextX) >= (place.x + stageX));
  const downConditional = (nextY < (place.y + stageY) + place.height);
  const upConditional = ((nextY) >= (place.y + stageY));

  if (leftConditional && rightConditional && upConditional && downConditional && !doorConditional) {
    return false;
  }

  if (doorConditional) triggerTransition(place.navigate, place.external);

  return true;
}

export default function useMovement(guyRef, tile, preStage, prePlaces, triggerTransition) {
  // converting to use tiles
  const STAGE = { height: (preStage.height * tile), width: (preStage.width * tile) };
  const places = prePlaces.map(place => ({
    ...place,
    x: place.x * tile,
    y: place.y * tile,
    height: place.height * tile,
    width: place.width * tile,
    doorX: place.doorX * tile,
  }));

  const [stageX, setStageX] = useState(tile * 1);
  const [stageY, setStageY] = useState(tile * 1);
  const [guyX, setGuyX] = useState(tile * 6);
  const [guyY, setGuyY] = useState(tile * 8);
  const [stateDirection, setDirection] = useState('down');


  const doMovement = (direction) => {
    // get movement results
    const isInMovementBounds = inMovementBounds(guyRef, direction, tile);
    const isInStageBounds = inStageBounds(guyX, guyY, direction, stageY, stageX, tile, STAGE);

    // check to see if the guy is moving into all place objects
    for (let i = 0; i < places.length; i += 1) {
      const isInPlaceBounds = inPlaceBounds(
        guyX,
        guyY,
        tile,
        places[i],
        stageX,
        stageY,
        direction,
        triggerTransition,
      );
      if (!isInPlaceBounds) return;
    }


    // if guy is in movement bounds and in stage bounds, move stage accordingly
    if (direction === 'left' && isInMovementBounds && isInStageBounds) {
      setStageX(stageX + tile);
    } else if (direction === 'right' && isInMovementBounds && isInStageBounds) {
      setStageX(stageX - tile);
    } else if (direction === 'down' && isInMovementBounds && isInStageBounds) {
      setStageY(stageY - tile);
    } else if (direction === 'up' && isInMovementBounds && isInStageBounds) {
      setStageY(stageY + tile);
    }
    // if guy is still in the movement bounds prevent guy from moving. and
    // if the guy is out of bounds prevent guy from moving.
    if (isInMovementBounds || !isInStageBounds) return;

    // otherwise set guys movement accordingly.
    if (direction === 'left') {
      setGuyX(guyX - tile);
    } else if (direction === 'right') {
      setGuyX(guyX + tile);
    } else if (direction === 'up') {
      setGuyY(guyY - tile);
    } else if (direction === 'down') {
      setGuyY(guyY + tile);
    }
  };

  const handleMovement = (e) => {
    window.requestAnimationFrame(() => {
      let direction;
      switch (e.keyCode) {
        case 37:
          direction = 'left';
          break;
        case 38:
          direction = 'up';
          break;
        case 39:
          direction = 'right';
          break;
        case 40:
          direction = 'down';
          break;
        default:
          return;
      }
      setDirection(direction);

      doMovement(direction);
    });
  };

  const handleClick = (direction) => {
    // window.requestAnimationFrame(() => {
      setDirection(direction);
      doMovement(direction);
    // });
  };


  // add event listener
  useEffect(() => {
    window.addEventListener('keydown', handleMovement);

    return function cleanup() {
      window.removeEventListener('keydown', handleMovement);
    };
  });

  return [
    stageX,
    stageY,
    guyX,
    guyY,
    stateDirection,
    places,
    handleClick,
  ];
}
