import portfolio from '../../../assets/map/buildings/portfolio.png';
import apps from '../../../assets/map/buildings/apps.png';
import blog from '../../../assets/map/buildings/blog.png';
import contact from '../../../assets/map/buildings/contact.png';
import cv from '../../../assets/map/buildings/cv.png';

// import settings from '../settings';

const map = [
  {
    x: 2,
    y: 2,
    src: portfolio,
    height: 5,
    width: 5,
    doorX: 2,
    navigate: '/portfolio',
  },
  {
    x: 20,
    y: 4,
    src: cv,
    height: 5,
    width: 5,
    doorX: 2,
    navigate: '/',
    external: 'https://docs.google.com/document/d/132FtakYvJL4KmVemiWOxYQXr9wL_7F8MS64NopsQkrQ/',
  },
  {
    x: 19,
    y: 12,
    src: contact,
    height: 5,
    width: 5,
    doorX: 2,
    navigate: 'popup:contact',
  },
  {
    x: 12,
    y: 16,
    src: apps,
    height: 5,
    width: 5,
    doorX: 2,
    navigate: 'popup:apps',
  },
  {
    x: 4,
    y: 11,
    src: blog,
    height: 5,
    width: 5,
    doorX: 2,
    navigate: '/',
    external: 'https://atomizedobjects.com/',
  },
];

export default map;
