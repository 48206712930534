import { useEffect, useState } from 'react';

export default function useResize(callback) {
  useEffect(() => {
    window.addEventListener('resize', callback);

    return function cleanup() {
      window.removeEventListener('resize', callback);
    };
  });
}

export function useResizeWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  
  useEffect(() => {
    const setInnerWidth = () => setWidth(window.innerWidth);
    window.addEventListener('resize', setInnerWidth);

    return function cleanup() {
      window.removeEventListener('resize', setInnerWidth);
    };
  });

  return width;
}
