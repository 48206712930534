import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './gameengine.module.scss';

import { useResize } from '../../hooks';
import { useMovement } from './hooks';

import Guy from './components/guy';
import Stage from './components/stage';
import Place from './components/place';
import Controls from './components/controls';

import map from './map';
import settings from './settings';

const TILE = settings.tile;
const STAGE = settings.stage;

function Places({ places }) {
  return places.map(place => (
    <Place key={`${place.x}${place.y}`} {...place} />
  ));
}

export default function GameEngine({ startTransition, history }) {
  const triggerTransition = (navigate, external) => startTransition(history, navigate, external);

  const PLACES = [
    ...map.scenery,
  ];

  const guyRef = useRef(null);
  const [
    stageX,
    stageY,
    guyX,
    guyY,
    stateDirection,
    places,
    handleClick,
  ] = useMovement(
    guyRef,
    TILE,
    STAGE,
    PLACES,
    triggerTransition,
  );

  // handleClick('down');

  // reload when use resized to re calculate
  useResize(() => {
    window.location.reload();
  });

  // two stages are rendered, one for the background and one for the places
  //
  // this is so the places are at a higher z-index than the guy and the
  // background is a lower z-index than the guy
  return (
    <div className={styles.outer}>
      <div
        className={styles.container}
        style={{
          width: (STAGE.width * TILE),
          height: (STAGE.height * TILE),
        }}
      >
        <Stage
          x={stageX}
          y={stageY}
          width={STAGE.width * TILE}
          height={STAGE.height * TILE}
          tile={TILE}
        />

        <Guy
          elem={guyRef}
          x={guyX}
          y={guyY}
          sX={stageX}
          sY={stageY}
          direction={stateDirection}
          tile={TILE}
        />

        <Stage
          noBackground
          x={stageX}
          y={stageY}
          width={STAGE.width * TILE}
          height={STAGE.height * TILE}
          tile={TILE}
        >
          <Places
            places={places}
            stageX={stageX}
            stageY={stageY}
          />
        </Stage>
      </div>
      <Controls handleClick={handleClick} />
    </div>
  );
}

GameEngine.propTypes = {
  history: PropTypes.object.isRequired,
  startTransition: PropTypes.func.isRequired,
};
