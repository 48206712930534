import React from 'react';
import PopUp from '../../popup';

export default function ComingSoon({ ...props }) {
  return (
    <PopUp {...props}>
      <h1>Coming soon...</h1>
      <h2>Sorry, this part is still being built!</h2>
      <p>For the time being you can still checkout the other buildings, and for work examples, checkout my C.V section.</p>
      <p>For anything else feel free to contact me at:</p>
      <h3><strong>contact@mayger.dev</strong></h3>
    </PopUp>
  );
}
