import React from 'react';
import PropTypes from 'prop-types';

import styles from './place.module.scss';

export default function Place({
  src,
  x,
  y,
  height,
  width,
}) {
  return (
    <div
      className={styles.place}
      style={{
        top: y,
        left: x,
        height,
        width,
      }}
    >
      <img src={src} alt={`Missing Game Piece: ${src}`} title={`Portfolio Game Piece: ${src}`} />
    </div>
  );
}

Place.propTypes = {
  src: PropTypes.any.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
