import React from 'react';
import SnowStorm from 'react-snowstorm';

import GameEngine from '../../components/gameengine';
import GameInfo from '../../components/gameengine/components/gameinfo';
import Contact from '../../components/gameengine/components/popups/contact';
import Apps from '../../components/gameengine/components/popups/apps';
import ComingSoon from '../../components/gameengine/components/popups/comingsoon';

export default function Home({
  contact,
  closeContact,
  apps,
  closeApps,
  comingSoon,
  closeComingSoon,
  play,
  handleClick,
  ...props
}) {

  return (
    <>
      <GameEngine {...props} />
      <SnowStorm animationInterval={50} excludeMobile={false} flakesMax={30} snowColor="#cf6e00" vMaxY={3} />
      { !play && (<GameInfo handleClick={handleClick} />) }
      { contact && (<Contact handleClose={closeContact} />) }
      { apps && (<Apps handleClose={closeApps} />) }
      { comingSoon && (<ComingSoon handleClose={closeComingSoon} />) }
    </>
  );
}
