import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxLayer } from 'react-spring/renderprops-addons';

import { useResizeWidth } from '../../../../hooks';

import styles from './site.module.scss';

export default function Site({ ...props }) {
  const width = useResizeWidth();
  return (
    <>
      { !props.fullText && (
        <ParallaxLayer speed={1} offset={1} {...props} style={width >= 768 && { display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className={styles.container}>
            <span className={styles.browserBar}>
              <span className={styles.close} />
              <span className={styles.min} />
              <span className={styles.full} />
              <span className={styles.menu} />
              <span className={styles.urlBar} />
            </span>
            <div className={styles.inner}>
              <img src={props.src} />
            </div>
          </div>
        </ParallaxLayer>
      )}
      <ParallaxLayer {...props} speed={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className={props.fullText ? styles.containerFull : styles.containerSmall}>
          <span className={styles.browserBar}>
            <span className={styles.close} />
            <span className={styles.min} />
            <span className={styles.full} />
            <span className={styles.menu} />
            <span className={styles.urlBar} />
          </span>
          <div className={styles.inner}>
            {props.children}
          </div>
        </div>
      </ParallaxLayer>
    </>
  );
}

Site.propTypes = {
};

Site.defaultProps = {
};
