import React, { useRef } from 'react';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import PropTypes from 'prop-types';

export default function Logo({ src, title, left, width, ...props }) {
  return (
    <ParallaxLayer speed={0.3} offset={1} {...props} style={{ opacity: 0.3, marginLeft: `${left || 0}%` }}>
      <img style={{ maxWidth: width || 200, filter: 'grayscale(70%) blur(3px)' }} src={src} title={title} alt={`displaying: ${title} logo`} />
    </ParallaxLayer>
  );
}

Logo.propTypes = {
  color: PropTypes.string,
};

Logo.defaultProps = {
  color: 'transparent',
};
