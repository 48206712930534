import React from 'react';
import PopUp from '../../popup';

export default function Contact({ ...props }) {
  return (
    <PopUp {...props}>
      <h1>Apps</h1>
      <h2>Here are the apps that I have made using React Native.</h2>
      <h3>
        <strong>
          MaBudget
        </strong>
      </h3>
    </PopUp>
  );
}
