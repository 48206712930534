import React, { useRef } from 'react';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import PropTypes from 'prop-types';

export default function Section({ color, ...props }) {
  return (
    <ParallaxLayer {...props} speed={1} style={{ backgroundColor: color, height: '110vh', boxShadow: `${color} 0px 0px 64px 47px` }} />
  );
}

Section.propTypes = {
  color: PropTypes.string,
};

Section.defaultProps = {
  color: 'transparent',
};
