import React from 'react';
import styles from './controls.module.scss';
import arrow from '../../../../assets/controls/arrow.png';

export default function Controls({ handleClick }) {
  return (
    <div className={styles.container}>
      <button
        className={styles.left}
        onClick={() => handleClick('left')}
        type="button"
        tabIndex={-2}
      >
        <img src={arrow} alt="left controller" />
      </button>
      <button
        className={styles.right}
        onClick={() => handleClick('right')}
        type="button"
        tabIndex={-4}
      >
        <img src={arrow} alt="right controller" />
      </button>
      <button
        className={styles.bottom}
        onClick={() => handleClick('down')}
        type="button"
        tabIndex={-5}
      >
        <img src={arrow} alt="down controller" />
      </button>
      <button
        className={styles.top}
        onClick={() => handleClick('up')}
        type="button"
        tabIndex={-3}
      >
        <img src={arrow} alt="up controller" />
      </button>
    </div>
  );
}
