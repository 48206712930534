export default function makeForest({
  tree,
  x,
  y,
  width,
  height,
}) {
  const forest = [];
  for (let ix = 0; ix < width; ix += 1) {
    for (let iy = 0; iy < height; iy += 1) {
      forest.push({
        x: x + ix,
        y: y + iy,
        height: 2,
        width: 2,
        src: tree,
      });
    }
  }

  return forest;
}
