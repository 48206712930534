import one from '../../../assets/map/trees/1.png';
import two from '../../../assets/map/trees/2.png';
import three from '../../../assets/map/trees/3.png';
import four from '../../../assets/map/trees/4.png';
import five from '../../../assets/map/trees/5.png';
import six from '../../../assets/map/trees/6.png';

import settings from '../settings';

import makeForest from './forest';

const trees = {
  one,
  two,
  three,
  four: six,
  five,
};

const forestTop = makeForest(
  {
    tree: trees.four,
    x: -0.5,
    y: -0.5,
    width: settings.stage.width,
    height: 2,
  },
);

const forestRight = makeForest(
  {
    tree: trees.four,
    x: settings.stage.width - 1.5,
    y: 1.5,
    width: 1,
    height: settings.stage.height - 3,
  },
);

const forestLeft = makeForest(
  {
    tree: trees.four,
    x: -0.5,
    y: 1.5,
    width: 1,
    height: settings.stage.height - 3,
  },
);

const lineOfTrees = makeForest(
  {
    tree: trees.three,
    x: 25.5,
    y: 6,
    width: 3,
    height: 1,
  },
);

const clusterOfTrees = makeForest(
  {
    tree: trees.four,
    x: 22,
    y: 18,
    width: 4,
    height: 2,
  },
);
const clusterOfTrees2 = makeForest(
  {
    tree: trees.four,
    x: 24,
    y: 20,
    width: 2,
    height: 2,
  },
);

const map = [
  {
    x: 6,
    y: 3,
    src: trees.three,
    height: 2,
    width: 2,
  },
  {
    x: 2,
    y: 8,
    src: trees.one,
    height: 2,
    width: 2,
  },
  {
    x: 11,
    y: 5,
    src: trees.two,
    height: 2,
    width: 2,
  },
  {
    x: 18,
    y: 9,
    src: trees.two,
    height: 2,
    width: 2,
  },
  {
    x: 26,
    y: 17,
    src: trees.one,
    height: 2,
    width: 2,
  },
  // {
  //   x: 16,
  //   y: 5,
  //   src: trees.three,
  //   height: 2,
  //   width: 2,
  // },
  // {
  //   x: 11,
  //   y: 8,
  //   src: trees.three,
  //   height: 2,
  //   width: 2,
  // },
  ...lineOfTrees,
  ...forestTop,
  ...forestLeft,
  ...forestRight,
  ...clusterOfTrees,
  ...clusterOfTrees2,
];

export default map;
