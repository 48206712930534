import pond from '../../../assets/map/patches/pond/1.png';

const patches = {
  pond,
};


const map = [
  {
    x: 13,
    y: 5,
    src: patches.pond,
    height: 5,
    width: 5,
  },
  {
    x: 3,
    y: 18,
    src: patches.pond,
    height: 4,
    width: 4,
  },
];

export default map;
