import React, { useRef } from 'react';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import Section from './components/section';
import Background from './components/background';
import Site from './components/site';
import Logo from './components/logo';

import evol from '../../assets/parallaxed/evolofmusic.jpg';
import cities from '../../assets/parallaxed/cities.jpg';
import exclusive from '../../assets/parallaxed/exclusive.jpg';
import atomized from '../../assets/parallaxed/atomizedobjects.jpg';
import vodafone from '../../assets/parallaxed/vodafone.jpg';
import seahorse from '../../assets/parallaxed/seahorse.jpg';
import natwest from '../../assets/parallaxed/natwest.jpg';

import reactLogo from '../../assets/parallaxed/logos/react.png';
import JSLogo from '../../assets/parallaxed/logos/js.png';
import CSSL from '../../assets/parallaxed/logos/css.png';
import HTMLL from '../../assets/parallaxed/logos/html.png';
import EXPL from '../../assets/parallaxed/logos/expressjs.png';
import FIREL from '../../assets/parallaxed/logos/firebase.png';
import GATSL from '../../assets/parallaxed/logos/gatsby.png';
import MODULEL from '../../assets/parallaxed/logos/modules.png';
import NODEL from '../../assets/parallaxed/logos/node.png';
import REDL from '../../assets/parallaxed/logos/redux.png';
import SASSL from '../../assets/parallaxed/logos/sass.png';
import VUEL from '../../assets/parallaxed/logos/vue.png';

export default function Parallaxed() {
  const parallax = useRef(null);
  return (
    <Parallax ref={parallax} pages={8} style={{ backgroundColor: '#20232f', position: 'fixed' }}>
      <Section offset={1} color={'#805E73'} />
      <Section offset={3} color={'#87BCDE'} />
      <Section offset={5} color={'pink'} />
      <Section offset={7} color={'#008C8C'} />
      <Background pages={8} />
      <Logo src={reactLogo} offset={0.9} width={300} />
      <Logo src={JSLogo} speed={-0.3} offset={1} left={80} />
      <Logo src={CSSL} width={100} left={5} />
      <Logo src={HTMLL} speed={1.5} offset={0.999} left={40} width={100} />
      <Logo src={EXPL} offset={1.6} />
      <Logo src={FIREL} speed={0.5} offset={1.9} left={20} width={300} />
      <Logo src={GATSL} offset={2} left={10}/>
      <Logo src={MODULEL} speed={1} offset={2.9999} left={10} />
      <Logo src={NODEL} speed={-0.5} offset={1} left={60} />
      <Logo src={REDL} offset={1.99}  left={75} />
      <Logo src={SASSL} offset={3} left={45} />
      <Logo src={VUEL} speed={-0.2} offset={2.8} left={75} />
      <Site offset={0} src={evol}>
        <h2>
          Brand USA
        </h2>
        <h3>
          Evolution of music
        </h3>
        <a
          href="https://www.telegraph.co.uk/travel/discover-america/evolution-of-music/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          https://www.telegraph.co.uk/travel/discover-america/evolution-of-music/
        </a>
        <p>
          This project was built using <strong>ReactJS</strong>, <strong>Sass</strong> and <strong>Redux</strong>. It had a very short deadline of one week and a few days. It consists of one main parallax that pauses on each section to allow various other effects to happen, all of which are on scroll.
        </p>
      </Site>
      <Site offset={1} src={cities}>
        <h2>
          Brand USA
        </h2>
        <h3>
          Cities of music
        </h3>
        <a
          href="https://www.telegraph.co.uk/travel/discover-america/greatest-music-cities/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          https://www.telegraph.co.uk/travel/discover-america/greatest-music-cities/
        </a>
        <p>
          This project was built using <strong>ReactJS</strong>, <strong>React Hooks</strong>, and <strong>CSS Modules</strong>. This project has a few different animations going on. The main one is a mouse parallax, which I wrote from scratch as it needed to work in a particular way. The second, is a standard parallaxing effect in the background. Finally there are also a few css animations on the larger images to create some transition style effects when it becomes visible.
        </p>
      </Site>
      <Site offset={2} src={atomized}>
        <h2>
          Personal Blog
        </h2>
        <h3>
          Atomized Objects
        </h3>
        <a
          href="https://atomizedobjects.com/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          https://atomizedobjects.com/
        </a>
        <p>
          I built my blog using <strong>GatsbyJS</strong>, <strong>ReactJS</strong>, <strong>GraphQL</strong>, <strong>CSS Modules</strong>, and <strong>Mark Down</strong>. It is a fairly simple build, It started as something much more complex with a complex grid system in the ui with images and various sections. I have since removed this and rebuilt it using the Gatsby starter blog template as a base as it offers a simplistic design along with fast speeds.
        </p>
      </Site>
      <Site offset={3} src={exclusive}>
        <h2>
          The Telegraph Travel
        </h2>
        <a
          href="https://www.telegraph.co.uk/travel/exclusiveoffers/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          https://www.telegraph.co.uk/travel/exclusiveoffers/
        </a>
        <p>
          I built this project using <strong>ReactJS</strong>, <strong>Redux</strong>, <strong>Sass</strong>, <strong>Google Sheets</strong>, and <strong>NodeJS</strong>. This build consists of many nested carousels that need to be controlled by google sheets entries. On top of this new carousels need to be added in dynamically by adding a new tab on google sheets. Due to the systems used at the telegraph it needed to be statically served, and it needed to be pre-rendered every time google sheets was updated, which caused some issues that I was able to overcome.
        </p>
      </Site>
      <Site offset={4} src={natwest}>
        <h2>
          NatWest
        </h2>
        <h3>
          Future of manufacturing
        </h3>
        <a
          href="https://www.telegraph.co.uk/business/tips-for-the-future/future-of-manufacturing/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          https://www.telegraph.co.uk/business/tips-for-the-future/future-of-manufacturing/
        </a>
        <p>
        I used <strong>VueJS</strong> and <strong>Scoped Sass</strong> to build this project. It is a page that displays a lot of information with nice smooth scrolling transitions and various components built in VueJS.
        </p>
      </Site>
      <Site offset={6} src={vodafone}>
        <h2>
          Vodafone
        </h2>
        <a
          href="https://www.telegraph.co.uk/travel/holidays/alternative-destinations/where-should-i-go-on-holiday-quiz/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          https://www.telegraph.co.uk/travel/holidays/alternative-destinations/where-should-i-go-on-holiday-quiz/
        </a>
        <p>
          I built this quiz using <strong>ReactJS</strong>, <strong>Redux</strong>, and <strong>Sass</strong>. This project functions as a small quiz type page where you choose a few options and get a result depending on what you have chosen.
        </p>
      </Site>
      <Site offset={7} fullText>
        <h2>
          Will Mayger.
        </h2>
        <h3>
          Front end, Javascript, TypeScript, ReactJS, React Native, Apollo GraphQL.
        </h3>
        <p> <br/> </p>
        <p>Feel free to email me at <strong>contact@mayger.dev</strong></p>
        <p> <br/> </p>
        <p>
          I have worked on many more projects, however I cannot show all of these projects due to them being internal. Most of these projects consist of UI's for handling data, and various mini CMS.
        </p>
        <p>For further information, please checkout my{' '}
          <a
            data-class="inline"
            href="https://docs.google.com/document/d/132FtakYvJL4KmVemiWOxYQXr9wL_7F8MS64NopsQkrQ"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            CV here
          </a>, 
          or find it and more information at the homepage of this site 
          (<a
            data-class="inline"
            href="https://willmayger.co.uk/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            https://willmayger.co.uk/
          </a>).
          </p>
          <p> <br/> </p>
        <p>
          Finally, here are a few more examples of my work:
        </p>
        <ul>
          <li>
            <a
              href="https://www.telegraph.co.uk/newsletters/homepage/"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              https://www.telegraph.co.uk/newsletters/homepage/
            </a>
          </li>
          <li>
              MaBudget 
          </li>
          <li>
            <a
              href="https://www.telegraph.co.uk/business/future-technologies/tools-to-increase-productivity/"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              https://www.telegraph.co.uk/business/future-technologies/tools-to-increase-productivity/
            </a>
          </li>
          <li>
            <a
              href="http://www.hwm.co.uk/"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              http://www.hwm.co.uk/
            </a>
          </li>
          <li>
            <a
              href="http://www.gcmotors.co.uk/"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              http://www.gcmotors.co.uk/
            </a>
          </li>
        </ul>
      </Site>
    </Parallax>
  );
}
